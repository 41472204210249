<template>
  <div
    id="management-report"
    class="management-report mb-4 pb-4 position-relative"
  >
    <h5 class="text-primary">Management Report</h5>
    <div class="filter-section"></div>
    <ReportTable
      :items="getManagementReportCount"
      :selectedColumn="getSelectedColumn"
      @columnClick="columnClick"
      :currentPayload="currentPayload"
      :filter="filter"
      @updateFilter="updateFilter"
    />
    <div ref="candidateListReport">
      <!-- <CandidateListReport
        v-if="checkIsColumnClicked"
        :totalCandidates="getJobCandidatesByStatusIDCount"
        :jobCandidates="getJobCandidatesByStatusID"
        :isFetchingJobCandidate="isFetchingJobCandidateSts"
        :selectedColumn="getSelectedColumn"
        @pageChanged="pageChanged"
      /> -->
    </div>
    <button
      v-if="isScrollTop"
      @click="scrollToTop()"
      id="btn-back-to-top"
      class="btn btn-primary btn-floating btn-lg"
      title="Go to top"
    >
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import Select from "@/components/reusable/Fields/Select";
import ReportTable from "@/containers/ManagementReport/ReportTable";
import CandidateListReport from "@/containers/ManagementReport/CandidateListReport";
import { mapActions, mapGetters } from "vuex";
import {
  isEmptyObjectCheck,
  isObject,
  getFilterQueryStringWithoutArray,
} from "../helpers/helper";
export default {
  name: "ManagementReport",
  components: {
    Select,
    ReportTable,
    CandidateListReport,
  },
  data() {
    return {
      filterData: {},
      currentPayload: {},
      column_payload: {
        matched: {
          tab_id: 1,
          filter_id: 2,
          payload: {
            user_action: "display",
          },
        },
        applied: {
          tab_id: 1,
          filter_id: 2,
          payload: {
            job_applied: true,
            user_action: "display",
          },
        },
        shortlist: {
          tab_id: 3,
          filter_id: [5, 6],
          payload: {
            user_action: "display",
          },
        },
        shortlist_1: {
          tab_id: 3,
          filter_id: 5,
          payload: {
            user_action: "display",
          },
        },
        shortlist_2: {
          tab_id: 3,
          filter_id: 6,
          payload: {
            user_action: "display",
          },
        },
        submitted: {
          tab_id: 4,
          filter_id: 27,
          payload: {
            user_action: "display",
          },
        },
        scheduled: {
          tab_id: 4,
          filter_id: 28,
          payload: {
            sub_filter_id: 18,
            user_action: "display",
          },
        },
        interviewed: {
          tab_id: 4,
          filter_id: 28,
          payload: {
            sub_filter_id: 20,
            user_action: "display",
          },
        },
        offered: {
          tab_id: 4,
          filter_id: 29,
          payload: {
            sub_filter_id: 21,
            user_action: "display",
          },
        },
        joined: {
          tab_id: 4,
          filter_id: 30,
          payload: {
            sub_filter_id: 24,
            user_action: "display",
          },
        },
      },
      isScrollTop: true,
      filter: {
        candidate_type_ids: [{ label: "All", code: 0 }],
        group_ids: [{ label:"All", code: 0}]
      },
    };
  },
  computed: {
    ...mapGetters([
      "getManagementReportCount",
      "getSelectedColumn",
      "getJobCandidatesByStatusID",
      "getJobCandidatesByStatusIDCount",
      "isFetchingJobCandidateSts",
    ]),
    options() {
      return {
        job: [],
      };
    },
    checkIsColumnClicked() {
      return (
        isObject(this.getSelectedColumn) &&
        !isEmptyObjectCheck(this.getSelectedColumn)
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchJobCandidateProcessCount",
      "setSelectedColumn",
      "initFetchJobCandidateReport",
      "fetchJobCandidatesReport",
      "scrollTop",
      "fetchUserConfiguration",
    ]),
    scrollToTop() {
      this.scrollTop();
    },
    onBodyScroll(e) {
      if (e.target.scrollTop > 20) {
        this.isScrollTop = true;
      } else {
        this.isScrollTop = false;
      }
    },
    columnClick(column_name, item, page = 1) {
      const column_payload = this.column_payload;
      if (column_payload[column_name]) {
        const { job_id, job_title, job_display_uid } = item;
        const selectedColumn = {
          job_id,
          column_name,
          job_title,
          job_display_uid,
        };
        this.setSelectedColumn(selectedColumn);
        let payload = { ...column_payload[column_name] };
        payload.payload["job_id"] = job_id;
        const pageNo = parseInt(this.$router.currentRoute?.query?.page || 1);
        const { skip, limit } = this.getSkipLimitBypage(pageNo);
        payload = {
          ...payload,
          skip,
          limit,
        };
        this.currentPayload = { ...column_payload[column_name] };
        let queryPayload = this.$router.currentRoute?.query || {};
        queryPayload = { ...queryPayload, ...selectedColumn };
        if (page) {
          queryPayload = { ...queryPayload, page };
        }
        this.$router.push(
          `/management-report?${getFilterQueryStringWithoutArray(queryPayload)}`
        );
        this.initFetchJobCandidateReport(payload).then((res) => {
          // this.goto("candidateListReport");
        });
      }
    },
    pageChanged(pageNumber = 1) {
      const { skip, limit } = this.getSkipLimitBypage(pageNumber);
      const { job_id, ...rest } = this.currentPayload;
      let query = this.$route.query || {};
      query.page ? delete query.page : null;
      this.$router.push(
        `/management-report?page=${pageNumber}&${getFilterQueryStringWithoutArray(
          query
        )}`
      );
      this.fetchJobCandidatesReport({
        pagination: false,
        skip,
        limit,
        ...this.currentPayload,
      });
    },
    getSkipLimitBypage(pageNumber) {
      const candidatesPerPage = 10;
      const skip = (pageNumber - 1) * candidatesPerPage || 0;
      const limit = candidatesPerPage;
      return { skip, limit };
    },
    goto(refName) {
      this.$refs[refName].scrollIntoView(true);
    },
    updateFilter(filter) {
      this.filter = filter;
      let finalPayload = {}
      if(!filter?.candidate_type_ids[0]?.code == 0) {
        finalPayload.jobs___candidate_type_id__in = this.filter?.candidate_type_ids?.map(val => val.code)
      };
      if(!filter?.group_ids[0]?.code == 0) {
        finalPayload.jobs___group_ids__overlap = `{${(this.filter?.group_ids?.map(val => val.code))}}`
      };
      this.fetchJobCandidateProcessCount(finalPayload)
    },
  },
  mounted() {
    this.fetchUserConfiguration().then((res) => {
      this.fetchJobCandidateProcessCount().then((res) => {
        if (res?.status === 200) {
          const { data } = res;
          let query = this.$route.query || {};
          const { page, pagination, ...rest } = query;
          const selectedColumn = rest ? { ...rest } : {};

          const selectedRow = data.find(
            (e) => e?.job_id == selectedColumn?.job_id
          );
          if (selectedRow) {
            const { column_name, job_id, job_display_uid, job_title } =
              selectedColumn;
            this.columnClick(column_name, { ...selectedRow }, page);
          }
        }
      });
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
#btn-back-to-top {
  position: fixed;
  right: 40px;
  bottom: 80px;
}
.btn-floating {
  border-radius: 50%;
  padding: 0;
  position: relative;
}
.btn-floating.btn-lg,
.btn-group-lg > .btn-floating.btn {
  width: 2.8125rem;
  height: 2.8125rem;
}
</style>
